import React, { useState, useRef } from 'react'
import { useAuth } from '../../contexts/AuthContext'

import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e){
    e.preventDefault()
    try{
        setError('')
        setLoading(true)
        await login(emailRef.current.value, passwordRef.current.value)
        history.push('/panel')
    } catch{
        setError('Wystąpił problem z logowaniem.')
    }
    setLoading(false)
  }

  return (
    <Container>
      <Row className="w-100 mx-0">
          <Col xs={12} className="d-flex justify-content-center">
              <h2 className="mt-5">Zaloguj się</h2>
          </Col>
          <Col xs={12} className="d-flex justify-content-center align-items-center mt-3 flex-column">
              <Card className="w-100 mt-5 px-2 py-2" style={{maxWidth: "400px"}}>
                  <Card.Body>
                      {error && <Alert variant="danger">{error}</Alert>}
                      <Form onSubmit={handleSubmit}>
                          <Form.Group id="email" className="mt-2">
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="email" ref={emailRef} required />
                          </Form.Group>
                          <Form.Group id="password" className="mt-2">
                              <Form.Label>Hasło</Form.Label>
                              <Form.Control type="password" ref={passwordRef} required />
                          </Form.Group>
                          <Button disabled={loading} variant="primary" className="w-100 mt-3" type="submit">Zaloguj się</Button>
                      </Form>
                      <div className="w-100 text-center mt-3">
                          <Link to="/forgot">Zapomniałeś hasła?</Link>
                      </div>
                  </Card.Body>
              </Card>
              <div className="w-100 text-center mt-4" style={{maxWidth: "400px"}}>
                  Chcesz rozpocząć grę? <Link to="/signup">Stwórz konto</Link>
              </div>
              <div className="w-100 text-center mt-2" style={{maxWidth: "400px"}}>
                  <Link to="/">Wróc na stronę główną</Link>
              </div>
          </Col>
      </Row>
    </Container>
  )
}
