import React, { useContext, useState, useEffect } from 'react'
import app from '../firebase'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}){
    const [currentUser, setCurrentUser] = useState()
    const [currentUserInfo, setCurrentUserInfo] = useState()
    const [loading, setLoading] = useState(true)

    function signup(email, password){
        return app.auth().createUserWithEmailAndPassword(email, password).then(cred => {
            const dataToPush = {
                id: cred.user.uid,
                admin: false,
                email: email,
                password: password,
                nick: "",
                paid: false,
                show: false,
                points: 0,
                bonusPoints: 0,
                exactResults: 0,
                matchResults: 0,
                missedMatches: 0
            }
            app.database().ref(`users/${cred.user.uid}`).set(dataToPush)
        }).catch(error => error)
    }

    function login(email, password){
        return app.auth().signInWithEmailAndPassword(email, password)
    }

    function logout(){
        return app.auth().signOut()
    }

    function resetPassword(email){
        return app.auth().sendPasswordResetEmail(email)
    }

    function updateEmail(email){
        return currentUser.updateEmail(email)
    }

    useEffect(() => {
        const unsubscribe = app.auth().onAuthStateChanged(user => {
            setCurrentUser(user)

            if(user){
                console.log('get info')
                let refData = app.database().ref(`users/${user.uid}`)
                refData.on('value', snapshot => {
                    setCurrentUserInfo(snapshot.val())
                })
                setLoading(false)
            }
            else{
                setLoading(false)
            }
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        currentUserInfo,
        login,
        signup, 
        logout,
        resetPassword,
        updateEmail
    }

    return(
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}