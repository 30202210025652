import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert, Container, Col, Row } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'

function Signup(){
    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { signup } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(e){
        e.preventDefault()

        if(passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError('Hasła nie są takie same.')
        }

        try{
            setError('')
            setLoading(true)
            await signup(emailRef.current.value, passwordRef.current.value)
            history.push('/login')
        } catch(e){
            console.log(e)
            setError('Wystąpił problem z założeniem konta.')
        }

        setLoading(false)

    }

    return(
        <Container>
            <Row className="w-100 mx-0">
                <Col xs={12} className="d-flex justify-content-center">
                    <h2 className="mt-5">Stwórz konto</h2>
                </Col>
                <Col xs={12} className="d-flex justify-content-center align-items-center flex-column">
                    <Card className="w-100 mt-5 px-2 py-2" style={{maxWidth: "400px"}}>
                        <Card.Body>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} required />
                                </Form.Group>
                                <Form.Group id="password" className="mt-2">
                                    <Form.Label>Hasło</Form.Label>
                                    <Form.Control type="password" ref={passwordRef} required />
                                </Form.Group>
                                <Form.Group id="password-confirm" className="mt-2">
                                    <Form.Label>Powtórz hasło</Form.Label>
                                    <Form.Control type="password" ref={passwordConfirmRef} required />
                                </Form.Group>
                                <Button variant="primary" disabled={loading} className="w-100 mt-4" type="submit">Stwórz konto</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-4" style={{maxWidth: "400px"}}>
                        Masz już swoje konto? <Link to="/login">Zaloguj się</Link>
                    </div>
                    <div className="w-100 text-center mt-2" style={{maxWidth: "400px"}}>
                        <Link to="/">Wróc na stronę główną</Link>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Signup