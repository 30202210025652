import React from 'react'

import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <div>
      <Link to="/login">Zaloguj</Link>
      <Link to="/signup">Zarejestruj</Link>
    </div>
  )
}
