import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert, Container, Col, Row } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'

function Forgot(){
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e){
        e.preventDefault()

        try{
            setMessage('')
            setError('')
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setMessage('Sprawdź swoją pocztę po więcej instrukcji.')
        } catch{
            setError('Wystąpił błąd w trakcie resetowania hasła.')
        }

        setLoading(false)

    }

    return(
        <Container>
            <Row className="w-100 mx-0">
                <Col xs={12} className="d-flex justify-content-center">
                    <h2 className="mt-5">Przypomnij hasło</h2>
                </Col>
                <Col xs={12} className="d-flex justify-content-center align-items-center flex-column">
                    <Card className="w-100 mt-5 px-2 py-2" style={{maxWidth: "400px"}}>
                        <Card.Body>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email" className="mt-2">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} required />
                                </Form.Group>
                                <Button variant="primary" disabled={loading} className="w-100 mt-3" type="submit">Zresetuj hasło</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-4">
                        Chcesz rozpocząć grę? <Link to="/signup">Załóż konto</Link>
                    </div>
                    <div className="w-100 text-center mt-2" style={{maxWidth: "400px"}}>
                        <Link to="/">Wróc na stronę główną</Link>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Forgot