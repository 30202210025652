import './App.css';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './pages/PrivateRoute';

import Home from './pages/Home/Home'
import Signup from './pages/Signup/Signup'
import Login from './pages/Login/Login'
import Forgot from './pages/Forgot/Forgot'
import Panel from './pages/Panel/Panel'

function App() {
  return (
    <Container>
      <Router>
        <AuthProvider>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/signup" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/forgot" component={Forgot} />
            <PrivateRoute path="/panel" component={Panel} />  
          </Switch>
        </AuthProvider>
      </Router>
    </Container>
  );
}

export default App;
