import React from 'react'
import { Container, Button } from 'react-bootstrap'
import { Switch, Route, Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

import PanelMain from '../PanelMain/PanelMain'
import PanelSettings from '../PanelSettings/PanelSettings'

export default function Panel() {
  const { currentUser, currentUserInfo, logout } = useAuth()
  const history = useHistory()

  async function handleLogout(){
      await logout()
      history.push('/')
  }

  return (
    <Container>
      <Link to="/panel">Home</Link>
      <Link to="/panel/settings">Ustawienia</Link>
      <Button onClick={handleLogout}>Wyloguj</Button>
      <Switch>
        <Route path="/panel" component={PanelMain} exact />
        <Route path="/panel/settings" component={PanelSettings} />
      </Switch>
    </Container>
  )
}
