import React from 'react'

import { useAuth } from '../../contexts/AuthContext'

export default function PanelMain() {
    const { currentUser } = useAuth()

    return (
        <div>
            Witaj: {currentUser.email}
        </div>
    )
}
