import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyB2gf-Zp3ojt0mhY9JDC4a8sSIqxvqcbFM",
    authDomain: "our-hobby.firebaseapp.com",
    projectId: "our-hobby",
    storageBucket: "our-hobby.appspot.com",
    messagingSenderId: "734450704065",
    appId: "1:734450704065:web:122df50ac5a8108a7d3e40"
  };

const app = firebase.initializeApp(firebaseConfig)

export default app